import React, {useEffect, useState, useCallback, FC} from "react";

const images = [
  {src: "/img/top/index_01.jpg", alt: ""},
  {src: "/img/top/index_02.jpg", alt: ""},
  {src: "/img/top/index_03.jpg", alt: ""},
];

export const TopImage: FC<{ duration: number }> = ({duration}) => {

  const [imgIndex, setImgIndex] = useState(0);
  const nextImgIndex = (imgIndex + 1) % 3;

  const imgA = images[imgIndex];
  const imgB = images[nextImgIndex];

  const [animating, setAnimating] = useState(false);
  const animationTrigger = useCallback(() => setTimeout(() => setAnimating(true), duration), [duration]);

  useEffect(() => {
    animationTrigger();
  }, []);

  return (
      <div id="viewer">
        <img src={imgB.src} alt={imgA.alt}/>
        <img src={imgA.src} alt={imgA.alt}
             className={`topImage ${animating && 'topImage-fadeout'}`}
             onTransitionEnd={() => {
               setImgIndex(nextImgIndex);
               setAnimating(false);

               animationTrigger();
             }}/>
      </div>
  )
}
