import React, {FC} from 'react';
import {Link} from "react-router-dom";

export const Header: FC = () => {
  return (
      <header>
        <div id="headerInner">
          <h1>千葉県野田市｜非鉄金属リサイクル業務のプロ 泉金属工業株式会社</h1>
          <ul id="headerBtns">
            <li><span className="icon-printer"></span><Link to="/company#print">印刷ページ</Link></li>
            <li><span className="icon-leaf"></span><Link to="/kankyo">環境方針</Link></li>
          </ul>
          <Link to=""><img src="/img/common/logo.png" alt="泉金属工業株式会社" id="logo"/></Link>
          <nav id="gnavi">
            <ul>
              {/* @formatter:off */}
              <NavItem to="/business" title="事業内容" titleEn="BUSINESS"/>
              <NavItem to="/article" title="取り扱い品目" titleEn="ARTICLE"/>
              <NavItem to="/recruit" title="採用情報" titleEn="RECRUIT"/>
              <NavItem to="/company" title="会社概要" titleEn="COMPANY"/>
              <NavItem to="/contact" title="お問い合わせ" titleEn="CONTACT"/>
              {/* @formatter:on */}
            </ul>
          </nav>
        </div>
      </header>
  );
};

const NavItem: FC<{ to: string, title: string, titleEn: string }> = ({to, title, titleEn}) => {
  return (
      <li>
        <Link to={to} className="button">
          {title}<br/>
          <span>{titleEn}</span>
        </Link>
      </li>
  )
}
