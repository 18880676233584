import React, {FC} from 'react';
import {Head} from "../head";
import {Outlet} from "react-router";

export const EmptyLayout: FC = () => {
  return (
      <>
        <Head/>
        <Outlet/>
      </>
  );
};
