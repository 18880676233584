import React, {FC} from 'react';
import {Link} from "react-router-dom";

export const Footer: FC = () => {
  return (
      <footer>
        <div id="footerLink">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/business">事業内容</Link></li>
            <li><Link to="/article">取り扱い品目</Link></li>
            <li><Link to="/recruit">採用情報</Link></li>
            <li><Link to="/company">会社概要</Link></li>
            <li><Link to="/company#print">印刷ページ</Link></li>
            <li><Link to="/kankyo">環境方針</Link></li>
            <li><Link to="/contact">お問い合わせ</Link></li>
          </ul>
        </div>
        <div id="footerBg">
          <div className="footerInner clearfix">
            <ul id="footerSpace">
              <li className="spCenter">
                <p className="name">泉金属工業株式会社</p>
                <p> 千葉県野田市西三ヶ尾82<br/>
                  <strong>
                    TEL：04-7126-4111<br className="forPc"/>
                    FAX：04-7126-4112
                  </strong>
                </p>
              </li>
              <li>
                <p className="footTel">
                  <strong>営業時間</strong><br/>
                  月~土曜 8:00~18:00 日曜 9:00~17:00<br/>
                  ※受入れは終了時間の30分前迄にお願いします。<br/>
                  ※祝日は定休日です。<br/>
                  ※詳細は<Link to="/">HOME</Link>のカレンダーを参照ください。</p>
              </li>
              <li>
                <p>
                  <img src="/img/common/img_iso.png" alt="ISO14001" className="iso" width={120}/><br/>
                  平成22年 ISO14001<br className="forPc"/> 取得認証致しました。
                </p>
              </li>
              <li>
                <Link to="/contact">
                  <img src="/img/common/bnr_mail.png" alt="お問い合わせ" className="bnrTel"/>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <p id="copyright">Copyright ©Izumi Kinzoku All rights reserved.</p>
      </footer>
  );
};
