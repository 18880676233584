import React, {FC} from 'react';
import Helmet from 'react-helmet';

export const Head: FC = () => {
  return (
      <Helmet>
        <title>HOME｜非鉄金属リサイクル 泉金属工業株式会社</title>
        <meta name="robots" content="index, follow"/>
        <meta name="description" content="非鉄金属スクラップの回収・買取は泉金属工業株式会社へ。ステンレス・銅・真鍮・砲金・アルミ缶・被覆電線等の高価買取・リサイクルは全てお任せください。"/>
        <meta name="keywords" content="野田市,千葉,泉金属工業株式会社,リサイクル,ステンレス,銅,真鍮,アルミ缶,被覆電線,非鉄金属,スクラップ,回収,高価買取"/>
      </Helmet>
  )
}
