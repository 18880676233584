import React, {FC} from 'react';
import Helmet from 'react-helmet';
import {Link} from "react-router-dom";
import {Link2Top} from "../../Components/Link2Top";
import {Nl2Br} from "core/UI/Components/Nl2Br";

export const KankyoPage: FC = () => {
  return (
      <>
        <Helmet>
          <title>環境方針｜非鉄金属リサイクル 泉金属工業株式会社</title>
        </Helmet>

        <div id="pageTtlBg">
          <div id="pageTtlInner">
            <img src="/img/page/pagrttl_bg06.jpg" alt=""/>
            <h2>環境方針</h2>
          </div>
        </div>
        <ol id="pan">
          <li><Link to="/">HOME</Link></li>
          <li>環境方針</li>
        </ol>

        <div id="container">
          <h3>環境規格</h3>
          <p>
            <Nl2Br text={`
              当社は国際規格である、環境マネジメントシステム「ISO14001」を2010年2月に認証取得しました。
              当社の事業活動の中で、環境への影響を考慮し、地球にやさしい環境保全につとめています。
            `}/>
          </p>

          <h3>基本理念</h3>
          <p>
            泉金属工業株式会社は非鉄金属資源再生業を業務とし、地域及び地球規模での環境保全の重要性を深く認識し、
            各種原料の受入れ、製造、出荷の各段階における環境との関わりを評価し、環境調和型社会への実現に寄与します。
          </p>

          <h3>環境方針</h3>

          <ol id="kankyo">
            <li>環境関連法規制及び地域との環境に関する協定等の約束事項を順守するとともにその達成の維持に努めます。</li>
            <li>環境方針、環境目的、目標を定め、その実現を図り、定期的な見直しをすることにより、環境マネジメントシステムを継続的に改善し、資源の有効活用に努めます。</li>
            <li>業務活動において、エネルギー削減、環境汚染物質及び廃棄物の削減による汚染の予防に努めます。</li>
          </ol>
          <div className="clearfix">
            <img src="/img/page/iso.gif" alt="ISO14001" className="imgL"/>
            <p className="right txtR pt40">
              <Nl2Br text={`
                2009年12月25日
                泉金属工業株式会社
                代表取締役 三上 健二
                `}/>
            </p>
          </div>

          <Link2Top/>
        </div>
      </>
  );
};

