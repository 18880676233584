import React, {FC} from 'react';
import Helmet from 'react-helmet';

export const NoMatchPage: FC = () => {
  return (
      <>
        <Helmet>
          <title>404 Not Found</title>
          <meta name="robots" content="noindex"/>
        </Helmet>

        <div id="container">
          <div style={{display: 'block', width: '100%', height: 100}}>
            <h1 style={{fontSize: 'x-large'}}>404 Not Found</h1>
          </div>

          <span style={{marginLeft: 5}}>
            <a href={'/'}>Homeにもどる</a>
          </span>
        </div>
      </>
  );
};
