import React, {FC} from "react";

export const Link2Top: FC = () => {
  return (
      <div className="pagetop"
           style={{cursor: 'pointer'}}
           onClick={handleClick}>
        <img src="/img/common/pagetop.png" alt="PAGETOP"/>
      </div>
  );
}

function handleClick() {
  document.querySelector('#top')?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
}
