import React, {FC, useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import {Link} from "react-router-dom";
import {Link2Top} from "../../Components/Link2Top";
import {Nl2Br} from "core/UI/Components/Nl2Br";
import {WebDataType} from "Front/API/AppRouter/WebData";
import {RecruitType} from "Front/API/AppRouter/WebData/Recruits";
import {useTRPC} from "Front/UI/App";

export const RecruitPage: FC = () => {
  const trpc = useTRPC();

  useEffect(() => {
    (async () => {
      const list = await trpc.web.recruits.list.query();
      setRecruits(list);
    })();
  }, []);

  const [recruits, setRecruits] = useState<WebDataType<RecruitType[]>[]>([]);
  return (
      <>
        <Helmet>
          <title>採用情報｜非鉄金属リサイクル 泉金属工業株式会社</title>
        </Helmet>

        <div id="pageTtlBg">
          <div id="pageTtlInner">
            <img src="/img/page/pagrttl_bg05.jpg" alt=""/>
            <h2>採用情報</h2>
          </div>
        </div>
        <ol id="pan">
          <li><Link to="/">HOME</Link></li>
          <li>採用情報</li>
        </ol>
        <div id="container">
          <h3>現在募集中の情報<span>RECRUIT</span></h3>
          <p>
            <Nl2Br text={`
              弊社では現在下記の要項で募集をしております。
              まずは書類選考になりますので、履歴書を郵送してください。ご応募お待ちしております。
            `}/>
          </p>
          {recruits?.map((elem) => (
              <table className="tblBlue mt30" key={elem.id}>
                <tbody>
                {elem.content?.map(({key, value}, index) => (
                    <tr key={`${elem.id}-${index}`}>
                      <th>{key}</th>
                      <td>
                        <Nl2Br text={value}/>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
          ))}
          <div className="btnContact mt30">
            <Link to="/contact">お問い合わせ</Link>
          </div>

          <Link2Top/>
        </div>
      </>
  );
};

