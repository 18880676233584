import React, {FC} from 'react';
import {BrowserRouter, Route} from 'react-router-dom'
import {Routes} from "react-router";
import {ScrollManager} from "core/UI/Components";
import {Layout} from "../Layouts";
import {EmptyLayout} from "../Layouts/Empty";
import {HomePage} from "./Home";
import {NoMatchPage} from "./NoMatch";
import {BusinessPage} from "./Business";
import {ArticlePage} from "./Article";
import {RecruitPage} from "./Recruit";
import {CompanyPage} from "./Company";
import {ContactPage} from "./Contact";
import {KankyoPage} from "./Kankyo";


export const Front: FC = () => {
  return (
      <BrowserRouter>
        <ScrollManager/>
        <Routes>
          <Route element={<Layout/>}>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/business" element={<BusinessPage/>}/>
            <Route path="/article" element={<ArticlePage/>}/>
            <Route path="/recruit" element={<RecruitPage/>}/>
            <Route path="/company" element={<CompanyPage/>}/>
            <Route path="/contact" element={<ContactPage/>}/>
            <Route path="/kankyo" element={<KankyoPage/>}/>
          </Route>
          <Route element={<EmptyLayout/>}>
            <Route path="*" element={<NoMatchPage/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

