import React, {FC, useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import {Link} from "react-router-dom";
import {Link2Top} from "../../Components/Link2Top";
import {useTRPC} from "Front/UI/App";

//@formatter:off
const articles = [
  {title: "アルミ合金",     image: "/img/article/img_01.jpg", alt: "ステンレス"},
  {title: "アルミ鋳物",     image: "/img/article/img_02.jpg", alt: "被覆電線"},
  {title: "アルミサッシ",   image: "/img/article/img_03.jpg", alt: "アルミビス付サッシ"},
  {title: "被覆電線",       image: "/img/article/img_04.jpg", alt: "真鍮"},
  {title: "アルミ缶",       image: "/img/article/img_05.jpg", alt: "アルミ缶"},
  {title: "アルミホイール", image: "/img/article/img_06.jpg", alt: "アルミホイール"}
];
//@formatter:on


// タイプ
interface ModelType {
  acceptable: string[] | null,
  unacceptable: string[] | null,
}


export const ArticlePage: FC = () => {
  const trpc = useTRPC();

  useEffect(() => {
    (async () => {
      const model = await trpc.web.article.list.query();
      setModel(model.reduce((results, elem) => {
        switch (elem.id) {
          case 'acceptable':
          case 'unacceptable':
            results[elem.id] = elem.content;
        }
        return results;
      }, {acceptable: null, unacceptable: null} as ModelType));

    })();
  }, []);

  const [model, setModel] = useState<null | ModelType>(null);
  return (
      <>
        <Helmet>
          <title>取り扱い品目｜非鉄金属リサイクル 泉金属工業株式会社</title>
        </Helmet>

        <div id="pageTtlBg">
          <div id="pageTtlInner">
            <img src="/img/page/pagrttl_bg02.jpg" alt=""/>
            <h2>取り扱い品目</h2>
          </div>
        </div>
        <ol id="pan">
          <li><Link to="/">HOME</Link></li>
          <li>取り扱い品目</li>
        </ol>
        <div id="container">
          <h3>
            取り扱い品目
            <span>ARTICLE</span>
          </h3>
          <p>
            以下の非鉄金属リサイクル業務を行っております。この他にも取り扱いしていますのでお気軽に<Link to="/contact">お問合せください</Link>。
          </p>

          <div id="articleSpace">
            <h4 className="mid_title">法人向け</h4>
            <p><a href="#ippan">▼一般の方はこちら</a></p>

            {articles.map(({title, image, alt}, index) => (
                <div className="articleBox" key={index}>
                  <h4>{title}</h4>
                  <img src={image} alt={alt}/>
                </div>
            ))}

            <h4 id="ippan" className="mid_title">一般の方向け：資源（鉄くず・非鉄くず）の持込について</h4>
            <p>一般家庭から出る資源物の持ち込みも受入れております。</p>
            <h5 className="bgRed">受入できるもの</h5>
            <ul className="articleList">
              {model === null && <>読込中...</>}
              {model?.acceptable?.map((elem, index) => <li key={index}><span className="red">○</span>{elem}</li>)}
            </ul>
            <h5 className="bgBlue">受入不可</h5>
            <ul className="articleList">
              {model === null && <>読込中...</>}
              {model?.unacceptable?.map((elem, index) => <li key={index}><span className="blue">✕</span>{elem}</li>)}
            </ul>
            <p className="mt20">その他小型家電等の資源物は各自治体へお問い合わせください。</p>
          </div>

          <Link2Top/>
        </div>
      </>
  );
};

