import React, {FC} from 'react';
import Helmet from 'react-helmet';
import {Link} from "react-router-dom";
import {Link2Top} from "../../Components/Link2Top";
import {Nl2Br} from "core/UI/Components/Nl2Br";

export const BusinessPage: FC = () => {
  return (
      <>
        <Helmet>
          <title>事業内容｜非鉄金属リサイクル 泉金属工業株式会社</title>
        </Helmet>

        <div id="pageTtlBg">
          <div id="pageTtlInner">
            <img src="/img/page/pagrttl_bg.jpg" alt=""/>
            <h2>事業内容</h2>
          </div>
        </div>
        <ol id="pan">
          <li><Link to="/">HOME</Link></li>
          <li>事業内容</li>
        </ol>

        <div id="container">
          <h3>非鉄金属リサイクル</h3>

          <p>
            <Nl2Br text={`
              泉金属工業株式会社では非鉄金属リサイクル業務を行っております。
              非鉄金属等をスクラップ・再資源化し、徹底した選別作業により質の高い資源に再生します。
            `}/>
          </p>

          <h3>産業廃棄物中間処理</h3>
          <p>
            産業廃棄物の最終処分を行うために、脱水や焼却・中和等を行うことを中間処理と言います。中間処理には廃棄物を分別し、粉砕による減量化を行います。
          </p>

          <h3>産業廃棄物収集運搬業</h3>
          <p className="clearfix">
            <img src="/img/page/img03.jpg" className="imgR" alt=""/>
            産業廃棄物の収集運搬から廃棄物処理、それに伴う鉄・アルミ・ステンレスのリサイクルの引き取り等を行っております。
          </p>

          <Link2Top/>
        </div>
      </>
  );
};

