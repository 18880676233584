import React, {FC} from 'react';
import {Head} from "./head";
import {Header} from "./header";
import {Footer} from "./footer";
import {Outlet} from "react-router";

export const Layout: FC = () => {
  return (
      <>
        <Head/>
        <Header/>
        <Outlet/>
        <Footer/>
      </>
  );
};
