import React, {FC} from 'react';
import Helmet from 'react-helmet';
import {Link} from "react-router-dom";
import {Link2Top} from "../../Components/Link2Top";
import {Nl2Br} from "core/UI/Components/Nl2Br";

export const CompanyPage: FC = () => {
  return (
      <>
        <Helmet>
          <title>環境方針｜非鉄金属リサイクル 泉金属工業株式会社</title>
        </Helmet>

        <div id="pageTtlBg">
          <div id="pageTtlInner">
            <img src="/img/page/pagrttl_bg03.jpg" alt=""/>
            <h2>会社概要</h2>
          </div>
        </div>

        <ol id="pan">
          <li><Link to="/">HOME</Link></li>
          <li>会社概要</li>
        </ol>

        <div id="container">
          <h3>会社概要<span>COMPANY</span></h3>
          <table className="tblBlue">
            <tbody>
            <tr>
              <th>商号</th>
              <td colSpan={2}>泉金属工業株式会社</td>
            </tr>
            <tr>
              <th>所在</th>
              <td colSpan={2}>〒278-0015 千葉県野田市西三ヶ尾82<br/>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3099.1615003501565!2d139.91481122164532!3d35.92814187123561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018991ff6125289%3A0x4dc6d37fefd09368!2z5rOJ6YeR5bGe5bel5qWt77yI5qCq77yJ!5e0!3m2!1sja!2sjp!4v1674953845260!5m2!1sja!2sjp"
                    width="600" height="450" style={{border: 0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe>

                <br/>
                <small>
                  <a href={`https://goo.gl/maps/JUT6FGyGg6c2Yv6X9`}
                     style={{color: '#0000ff', textAlign: 'left'}}>
                    大きな地図で見る
                  </a>
                </small>
              </td>
            </tr>
            <tr>
              <th>TEL/FAX</th>
              <td colSpan={2}><b>TEL</b> : 04-7126-4111 <b>FAX</b> : 04-7126-4112</td>
            </tr>
            <tr>
              <th>代表者</th>
              <td colSpan={2}>三上健二</td>
            </tr>
            <tr>
              <th>設立</th>
              <td colSpan={2}>1989年8月</td>
            </tr>
            <tr>
              <th>資本金</th>
              <td colSpan={2}>1,000万円</td>
            </tr>
            <tr>
              <th>従業員数</th>
              <td colSpan={2}>20名</td>
            </tr>
            <tr>
              <th>業務内容</th>
              <td colSpan={2}>非鉄金属売買業、産業廃棄物中間処理、収集運搬業</td>
            </tr>
            <tr>
              <th>設備</th>
              <td colSpan={2}>
                <Nl2Br text={`
                  プレス機 1機
                  ベールマシン 1機
                  トラックスケール 2台（50ｔ・20ｔ）
                  トラック 10台
                  ユンボ 1台 
                  フォークリフト 4台
                `}/>
              </td>
            </tr>
            <tr>
              <th rowSpan={4}>許認可</th>
              <td width="294">産業廃棄物中間処分業許可</td>
              <td width="250">千葉県</td>
            </tr>
            <tr>
              <td>産業廃棄物収集運搬業許可</td>
              <td>
                <Nl2Br text={`
                  千葉県
                  東京都
                  埼玉県
                  茨城県
                `}/>
              </td>
            </tr>
            <tr>
              <td>古物商許可</td>
              <td>千葉県公安委員会</td>
            </tr>
            <tr>
              <td>ISO14001：2015認証登録</td>
              <td>日本規格協会</td>
            </tr>
            </tbody>
          </table>

          <h3 id="print">
            印刷用pdf<span>PRINTABLE</span>
          </h3>
          <p>
            上記の会社案内をA4サイズにて印刷して頂けます。
            以下の「会社案内の印刷ページはこちらから」のボタンをクリックしますと、印刷用のpdfが開きますのでご自由にご利用下さい。地図の印刷はGoogleマップのページをご利用下さい。 <br/>
            ※印刷にはアドビリーダーが必要です。
            <a href="http://www.adobe.com/jp/products/reader.html" target="_blank">
              <img src="/img/page/GetAdobeReader_icon.png" alt="GetAdobeReader"/>
            </a>
          </p>
          <div className="btnSpace">
            <p className="btnPrint">
              <a href="/img/page/companyprofile.pdf" target="_blank">会社案内の印刷ページはこちらから</a>
            </p>

            <p className="btnPrint">
              <a href="https://goo.gl/maps/JUT6FGyGg6c2Yv6X9" target="_blank">
                地図の印刷ページはこちらから
              </a>
            </p>
          </div>

          <Link2Top/>
        </div>
      </>);
};

