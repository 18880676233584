import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {TopImage} from "./TopImage";
import {Link2Top} from "../Components/Link2Top";
import {useTRPC} from "Front/UI/App";
import {WebDataType} from "Front/API/AppRouter/WebData";
import {NewsType} from "Front/API/AppRouter/WebData/News";
import {Nl2Br} from 'core/UI/Components';

export const HomePage = () => {

  const trpc = useTRPC();

  useEffect(() => {
    (async () => {
      const news = await trpc.web.news.list.query();
      setNews(news.sort((a, b) => -(a.id.localeCompare(b.id))));
    })();
  }, []);

  const [news, setNews] = useState<WebDataType<NewsType>[]>([]);

  return (
      <>
        <link href="/css/top.css" rel="stylesheet" type="text/css"/>
        <div id="topImage">
          <TopImage duration={6000}/>
        </div>

        <div id="container">
          <div className="flexSb-p">
            <div id="mainLeft">
              <div className="gc_wrapper">
                <div className="responsive-iframe-container big-container">
                  {/* 埋め込み用 HTMLコード */}
                  <iframe src="https://calendar.google.com/calendar/embed?title=%E5%96%B6%E6%A5%AD%E4%BA%88%E5%AE%9A&amp;showTitle=0&amp;showDate=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23666666&amp;src=b8domkt7rd96nkuarm5dl1scpc%40group.calendar.google.com&amp;color=%23A32929&amp;src=2ns2apehq4ppl8gaoubva64g4c%40group.calendar.google.com&amp;color=%232952A3&amp;ctz=Asia%2FTokyo"
                          style={{border: 0, overflow: "hidden"}} width="800" height="600">
                  </iframe>
                </div>

                <div className="responsive-iframe-container small-container">
                  <iframe src="https://calendar.google.com/calendar/embed?title=%E5%96%B6%E6%A5%AD%E4%BA%88%E5%AE%9A&amp;showTitle=0&amp;showDate=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23666666&amp;src=b8domkt7rd96nkuarm5dl1scpc%40group.calendar.google.com&amp;color=%23A32929&amp;src=2ns2apehq4ppl8gaoubva64g4c%40group.calendar.google.com&amp;color=%232952A3&amp;ctz=Asia%2FTokyo"
                          style={{border: 0, overflow: "hidden"}} width="470" height="270">
                  </iframe>
                </div>
              </div>

              <div className="cal_wrapper">
                <div className="googlecal"></div>
              </div>
            </div>

            <div id="mainRight">
              <h3>NEWS<span>ニュース</span></h3>
              <div id="news">
                <ul>
                  {!!news || '読込中...'}
                  {!!news && news.map(({content}, index) => <li key={index}><Nl2Br html={content}/>
                  </li>)}
                </ul>
              </div>
              <div className="news_item">
                <p>
                  <Link to="/article#ippan">一般家庭から出る資源物の持ち込みについて</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="top_bnrwrap flexSbB-p">
            <div>
              <Link to="/business">
                <img src="/img/top/bnr_business.jpg" alt="事業内容" className="bnrB"/>
              </Link>
            </div>
            <div>
              <Link to="/recruit">
                <img src="/img/top/bnr_recruit.jpg" alt="採用情報"/>
              </Link>
            </div>
          </div>

          <h3>ARTICLE<span>取り扱い品目</span></h3>
          <ul id="itemImg" className="clearfix">
            <li>
              <img src="/img/top/article_01.jpg" alt=""/><br/>
              <span>アルミ合金</span></li>
            <li>
              <img src="/img/top/article_02.jpg" alt=""/><br/>
              <span>被覆電線</span></li>
            <li>
              <img src="/img/top/article_03.jpg" alt=""/><br/>
              <span>アルミサッシ</span></li>
            <li>
              <img src="/img/top/article_04.jpg" alt=""/><br/>
              <span>アルミ鋳物</span></li>
            <li>
              <img src="/img/top/article_05.jpg" alt=""/><br/>
              <span>アルミ缶</span></li>
            <li>
              <img src="/img/top/article_06.jpg" alt=""/><br/>
              <span>アルミホイール</span>
            </li>
          </ul>

          <Link2Top/>
        </div>
      </>
  );
};

