import React, {FC} from 'react';
import Helmet from 'react-helmet';
import {Link} from "react-router-dom";
import {Link2Top} from "../../Components/Link2Top";
import {Nl2Br} from "core/UI/Components/Nl2Br";

export const ContactPage: FC = () => {
  return (
      <>
        <Helmet>
          <title>お問い合わせ｜非鉄金属リサイクル 泉金属工業株式会社</title>
        </Helmet>

        <div id="pageTtlBg">
          <div id="pageTtlInner">
            <img src="/img/page/pagrttl_bg04.jpg" alt=""/>
            <h2>お問い合わせ</h2>
          </div>
        </div>

        <ol id="pan">
          <li><Link to="/">HOME</Link></li>
          <li>お問い合わせ</li>
        </ol>

        <div id="container">
          <div id="formWrap">
            <h3>お問い合わせフォーム<span>CONTACT</span></h3>
            <p>下記フォームに必要事項を入力後、確認ボタンを押してください。※全て入力必須項目です。</p>
            <div id="form_inline">
              <iframe frameBorder="0" scrolling="auto" src="https://ssl.form-mailer.jp/fms/e02fca62674674" title="HTML Form" width="100%" height="100%">
                <a href="https://ssl.form-mailer.jp/fms/e02fca62674674" title="Contact">この部分はインラインフレームを使用しています</a>
              </iframe>
            </div>
          </div>

          <h3>電話番号＆FAX番号でのお問い合わせ<span>TEL ＆ FAX</span></h3>
          <p>お問い合わせフォームがご利用頂けない場合は以下よりお問い合わせください。</p>
          <p className="tel">
            <img src="/img/page/img02.jpg" alt="" className="imgR forPc"/>
            <span>TEL</span><span className="icon-phone"></span>04-7126-4111<br/>
            <span>FAX</span><span className="icon-printer2"></span>04-7126-4112
          </p>
          <p className="clearfix">
            <Nl2Br text={`
              【営業時間
              平日・土曜 9：00～17：00
              ※日・祝日はFAXのみの受付
            `}/>
          </p>

          <Link2Top/>
        </div>
      </>);
};

