const AwsExports = {
  Auth: {
    identityPoolId: 'ap-northeast-1:69763ad9-1356-4495-8fca-e32d863e7be2',
    userPoolId: 'ap-northeast-1_bwDwbIhgX',
    userPoolWebClientId: '6s4496b99ce8bqdmbag8ct2nu8',
    region: 'ap-northeast-1',
  },
  API : {
    'aws_appsync_graphqlEndpoint'   : 'https://5v4ra6p5vvecrpf6itkzy4tb2i.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    'aws_appsync_region'            : 'ap-northeast-1',
    'aws_appsync_authenticationType': 'AWS_IAM',
  },
};

export default AwsExports;
